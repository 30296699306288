import BillingService from '@/services/billing.service'
import getEnv from '@/util/env'
import getSymbolFromCurrency from 'currency-symbol-map'
import BrowserCache from '@/services/browser-cache.js'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import DateRange from '@/components/DateRange/DateRange.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'Billing',
  components: { DateRange, HeaderTopDashboard },
  data () {
    return {
      paymentMethodCreditCard: 2,
      show: false,
      searchTerm: '',
      loading: true,
      options: {
        sortBy: ['created'],
        sortDesc: [false],
      },
      content: [],
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      totalRows: 0,
      currencySymbol: getSymbolFromCurrency(BrowserCache.getJson('user').currencyCode),
      globalControl: new GlobalControl(),
      startDate: undefined,
      endDate: undefined,
      debouncedInput: undefined,
      timeout: undefined,
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('Nº Factura'), value: 'facturaId' },
        { text: this.$t('Fecha'), value: 'created' },
        { text: this.$t('Tipo'), value: 'is_proforma' },
        { text: this.$t('Método de pago'), value: 'paymentMethodName' },
        { text: this.$t('Detalles'), value: 'description' },
        { text: this.$t('Importe'), value: 'total' },
        { text: this.$t('Acciones'), value: 'id' },
      ]
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
  },
  watch: {
    params: {
      handler () {
        this.setParamsAndGetData()
      },
      deep: true,
    },
  },
  methods: {
    invoiceTotal (invoice) {
      return invoice.total * (invoice.is_abono ? -1 : 1)
    },
    selectDates (dates) {
      this.startDate = dates[0]
      this.endDate = dates[1]
      this.setParamsAndGetData()
    },

    getDataFromApi (params = {}) {
      this.content = []
      this.loading = true
      BillingService.getAll(params)
        .then(
          (response) => {
            this.content = response.data
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.totalRows = response.total
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
        })
    },
    setParamsAndGetData () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      if (this.options.sortBy) {
        for (let i = 0; i < this.options.sortBy.length; i++) {
          params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
          params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
            ? 'asc'
            : 'desc'
        }
      }

      this.getDataFromApi(params)
    },
    getDownloadFile (id) {
      const downloadUrl = 'api/billing/invoice/' + id + '/pdf'
      const user = JSON.parse(localStorage.getItem('user'))
      window.location.href = getEnv('backend_endpoint') + downloadUrl + '?download=1&locale=' + user.lang + '&token=' + user.token
    },
  },
  mounted () {
    this.startDate = this.globalControl.initDate
    this.endDate = this.globalControl.endDate
    this.setParamsAndGetData()
  },
}
